import React, { useState } from "react";
import styled from "styled-components";
import { Collapse } from "reactstrap";

const StyledFaq = styled.div`
  padding: 0.75rem;
  box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border-radius: 5px;
  .title {
    cursor: pointer;
    position: relative;
    padding-right: 40px;
  }
  .caret {
    font-size: 2rem;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform ease 0.4s;
    &.spin {
      transform: rotate(90deg);
    }
  }
  .collapse,
  .collapsing {
    padding: 1rem;
    cursor: auto;
  }
`;

const Button = props => {
  const [open, setOpen] = useState(false);
  return (
    <StyledFaq onClick={() => setOpen(!open)}>
      <div className="title">
        <h4>{props.question}</h4>
        <div className={`caret ${open ? `spin` : ``}`}>&#8250;</div>
      </div>
      <Collapse isOpen={open}>{props.children}</Collapse>
    </StyledFaq>
  );
};

export default Button;
