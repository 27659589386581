import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Faq from "components/faq";
import { Container } from "reactstrap";
import { PageHeroSection, Section } from "components/section";
import Layout from "components/Layout";

const SecondPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Frequently Asked Questions - CleanWright</title>
      </Helmet>
      <PageHeroSection>
        <h1>FAQs</h1>
      </PageHeroSection>
      <Section>
        <Container>
          <p className="mb-5">
            We hope you find the answers to any questions you may have here. If
            not though, feel free to call our main man Steve on 01823 666025.
            He’ll be more than happy to help you out, and may even be able to
            give you a quote over the phone.
          </p>
          <Faq question="What are the benefits of the Steam Cleaning process?">
            <div>
              <p>
                Here’s just a few of the benefits of steam cleaning your carpet:
              </p>
              <ul>
                <li>Most thorough carpet cleaning process available.</li>
                <li>
                  No harmful chemicals are used, which ensures safety for pets,
                  children and plants
                </li>
                <li>Prolongs the carpet life</li>
                <li>
                  <Link to="/service/deodoriser/">
                    Removes bad smells from your carpet
                  </Link>
                </li>
                <li>Makes heavily used areas look clean again</li>
                <li>Restores carpet pile</li>
                <li>Repels dirt instead of attracting it</li>
                <li>
                  <Link to="/service/allergy-protection/">
                    Extracts allergy causing pollens and spores
                  </Link>
                </li>
                <li>Kills germs and bacteria</li>
                <li>Extracts flea eggs</li>
                <li>Removes dead skin which dust mites live on</li>
              </ul>
            </div>
          </Faq>
          <Faq question="What System do cleanwright use?">
            <div>
              <p>
                I use the system known as Hot Water Extraction or ‘steam
                cleaning’. The carpet is pre-sprayed with a cleaning solution
                specially formulated for fresh water rinsing. The pre spray is
                then brushed into the carpet pile to allow soiling to be
                agitated and broken up and detached from the fibres.
              </p>
              <p>
                My high pressure, twin vacuum machine is then used to rinse the
                carpet pile with fresh water only. The machine uses hot water or
                steam if needed into the carpet at high pressure. This further
                breaks and releases any dirt, bacteria, chemicals and pollens.
                The machine uses large vacuum volume to draw the dirt and
                chemicals out of your carpet.
              </p>
              <p>
                Any spots or stains still remaining are then treated with
                appropriate products, which are also rinsed from the carpet
                using fresh water. The same system is used but with a smaller
                tool for upholstery, stairs, curtains, and mattresses.
              </p>
            </div>
          </Faq>
          <Faq question="Where are Cleanwright based?">
            <div>
              <p>
                Cleanwright provide carpet cleaning services in Taunton and
                Wellington primarily, however we serve our customers throughout
                the county of Somerset.
              </p>
              <p>
                If you require carpet cleaning in Taunton, Wellington or the
                surrounding areas, please <Link to="/quote/">get in touch</Link>
                !
              </p>
            </div>
          </Faq>
          <Faq question="How long will it take to dry?">
            <div>
              <p>
                Every effort is made to keep the drying time to a minimum. 3-6
                hours is a rough guide, and may be reduced. Generally speaking,
                with heating on and windows open, your carpet can be dry within
                3 hours.
              </p>
              <p>
                Natural fibres such as wool may take longer to dry than
                synthetics as they absorb more moisture.
              </p>
              <p>
                It is important to keep the room warm and well ventilated to
                shorten drying times.
              </p>
              <p>
                There seems to be little difference in drying times in Winter or
                Summer if you have central heating.
              </p>
              <p>
                Cleanwright provide carpet cleaning services in Taunton and
                Wellington primarily, however we serve our customers throughout
                the county of Somerset.
              </p>
              <p>
                If you require carpet cleaning in Taunton, Wellington or the
                surrounding areas, please <Link to="/quote/">get in touch</Link>
                !
              </p>
            </div>
          </Faq>
          <Faq question="Do you move the furniture?">
            <div>
              <p>
                Ideally all or most of the furniture should be moved out of the
                room to be cleaned before I arrive. But this is often not
                possible especially if there is more than one room to clean.
              </p>
              <p>
                Therefore I usually request that all smaller items are removed
                from the carpet. I can happily move larger pieces of furniture
                such as chairs sofas tables as I progress around the room. These
                items are replaced and special foil squares placed underneath
                where there is a danger of marking the carpet.
              </p>
            </div>
          </Faq>
          <Faq question="How much will it cost?">
            <div>
              <p>
                The cost depends on a variety of factors including degree of
                soiling, travelling time, amount of carpets, number of chairs
                sofas etc, and what extras you choose, stain treatments,
                deodorisers, insecticide treatment etc.
              </p>
              <p>
                I have a minimum charge of £60. The charge then reduces with
                volume of work.
              </p>
              <p>
                For smaller jobs I can usually give a quote over the phone but
                for larger jobs I prefer to pay you a prior visit to give a firm
                quotation.
              </p>
            </div>
          </Faq>
          <Faq question="How can I receive a quote?">
            <div>
              <p>
                Once you’re ready to take the next step, give Steve a call
                on&nbsp;<a href="tel:01823 666025">01823 666025.</a> He may be
                able to give you an estimate over the phone straight away!
              </p>
              <p>
                Alternatively for bigger jobs, you can arrange a time that suits
                you best for a home visit, where we can then accurately assess
                the price.
              </p>
              <p>
                If you’d rather not call, you can also
                <Link to="/quote">request a carpet cleaning quote online</Link>.
              </p>
            </div>
          </Faq>
        </Container>
      </Section>
    </Layout>
  );
};

export default SecondPage;
